<template>
    <div>
        <div v-if="data.id !== 0" @click="$emit('clickTimer')" class="timer" :class="[$mq, 'status-' + data.status, { active: data.status > 0 }]">
            <div v-if="data.same_product_status != null" :class="['rel-status', 'status-' + data.same_product_status]"></div>
            <header>
                <img class="img" :src="product.image" />
                <span ref="name" class="text" :class="spanClass">{{ product.name }}</span>
            </header>
            <div class="time">
                <template v-if="live && timeLeft.d == 0">
                    {{ timeLeft.h }}:{{ timeLeft.m }}<span class="seconds">:{{ timeLeft.s }}</span>
                </template>
                <template v-else-if="live && timeLeft.d != 0"> {{ timeLeft.d }} {{ $tc('timersproduct.day', timeLeft.d) }} </template>
                <template v-else>00:00</template>
            </div>
            <div v-if="data.isLoading" class="loading"></div>
        </div>
        <div v-else class="timer">
            <div class="loading"></div>
        </div>
    </div>
</template>

<script>
import { TIMERPRODUCT_STATUS } from '@/constants'

export default {
    props: {
        data: { type: Object },
        visibilityType: { type: Number }
    },
    data() {
        return {
            now: moment(),
            active: true,
            spanClass: ''
        }
    },
    computed: {
        timeLeft: function () {
            var now = this.now
            if (this.visibilityType == 1) {
                var ref_date = this.data.data.date_timestamp
                if (this.data.status == TIMERPRODUCT_STATUS.PREPARATION) {
                    ref_date = this.data.data.prep_date_timestamp
                }

                var duration = moment.duration(moment(ref_date * 1000).diff(now))

                var diff = duration.asSeconds()

                // Convert diff that are seconds in days, hours, minutes and seconds
                let diff_days = parseInt(diff / 86400)
                diff = diff - diff_days * 86400
                let diff_hours = String(parseInt(diff / 3600)).padStart(2, '0')
                diff = diff - diff_hours * 3600
                let diff_minutes = String(parseInt(diff / 60)).padStart(2, '0')
                diff = diff - diff_minutes * 60
                let diff_seconds = String(parseInt(diff)).padStart(2, '0')
                // var diff_days = String(parseInt(diff %  )) // days from diff
                // var diff_seconds = String(parseInt(diff % 60)).padStart(2, '0')
                // var diff_minutes = String(Math.floor((diff % 3600) / 60)).padStart(2, '0')
                // var diff_hours = String(Math.floor(diff / 3600)).padStart(2, '0')

                var diff = {
                    d: diff_days,
                    h: diff_hours,
                    m: diff_minutes,
                    s: diff_seconds
                }
                return diff
            } else {
                var ref_date = this.data.data.print_date_timestamp
                // if (this.data.status == TIMERPRODUCT_STATUS.PREPARATION) {
                //     ref_date = this.data.prep_date
                // }

                var duration = moment.duration(now.diff(moment(ref_date * 1000)))

                var diff = Math.abs(duration.asSeconds())

                // Convert diff in days, hours, minutes and seconds
                let diff_days = parseInt(diff / 86400)
                diff = diff - diff_days * 86400
                let diff_hours = String(parseInt(diff / 3600)).padStart(2, '0')
                diff = diff - diff_hours * 3600
                let diff_minutes = String(parseInt(diff / 60)).padStart(2, '0')
                diff = diff - diff_minutes * 60
                let diff_seconds = String(parseInt(diff)).padStart(2, '0')
                // var diff_seconds = String(parseInt(diff % 60)).padStart(2, '0')
                // var diff_minutes = String(Math.floor((diff % 3600) / 60)).padStart(2, '0')
                // var diff_hours = String(Math.floor(diff / 3600)).padStart(2, '0')

                var diff = {
                    d: diff_days,
                    h: diff_hours,
                    m: diff_minutes,
                    s: diff_seconds
                }
                return diff
            }
        },

        live() {
            return this.data.status != TIMERPRODUCT_STATUS.EXPIRED
        },

        product() {
            return this.$store.getters['timerproduct/getProducts'](this.data.reference_id)
        }
    },
    methods: {
        checkLines() {
            const span = this.$refs.name
            if (span) {
                const lineHeight = parseFloat(window.getComputedStyle(span).lineHeight)
                const spanHeight = span.offsetHeight

                if (spanHeight > lineHeight) {
                    this.spanClass = 'multi-line'
                } else {
                    this.spanClass = 'single-line'
                }
            } else {
                this.spanClass = 'multi-line'
            }
        }
    },
    mounted() {
        this.checkLines()
        window.addEventListener('resize', this.checkLines)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkLines)
    },
    created() {
        var self = this
        setInterval(function () {
            self.now = moment()
        }, 1000)
        this.checkLines()
        window.addEventListener('resize', this.checkLines)
    }
}
</script>

<style lang="scss" scoped>
.timer {
    padding: 0 2px;
    overflow: hidden;
    min-width: 0;
    height: 120px;
    background-color: $color-error-500;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    .loading {
        background-color: rgba(0, 0, 0, 0.3); // Oscurece el fondo con transparencia
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10; // Asegura que el loading esté sobre el contenido

        &::before {
            content: '';
            background-image: img('loader4_light.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 30px;
            width: 30px;
            height: 30px;
        }
    }

    &.status-0 {
        background-color: $color-error-500;
    }
    &.status-1 {
        background-color: $color-secondary-500;
    }
    &.status-2 {
        background-color: $color-success-500;
    }
    &.status-3 {
        background-color: $color-warning-500;
    }

    .rel-status {
        width: 16px;
        height: 16px;
        border: 2px solid #fff;
        background-color: red;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;

        &.status-0 {
            background-color: $color-error-500;
        }
        &.status-1 {
            background-color: $color-secondary-500;
        }
        &.status-2 {
            background-color: $done;
        }
        &.status-3 {
            background-color: $color-warning-500;
        }
    }

    header {
        width: 100%;
        text-align: center;
        padding: 5px;

        .img {
            width: 35px;
            height: 35px;
            display: block;
            margin: 0 auto;
        }

        .text {
            @include font-size(sm);
            line-height: 16px;
            @include text-ellipsis();
            font-family: $text;
            color: #fff;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            margin-top: 4px;
            display: block;
            @include text-ellipsis($line: 2);
            white-space: pre-line;

            &.single-line {
                padding-top: 0.6rem;
                padding-bottom: 0.6rem;
            }
        }
    }

    .time {
        width: 100%;
        font-family: Avenir-Bold;
        text-align: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 6px 4px;
        font-size: 22px;

        .seconds {
            font-size: 16px;
            color: #fff;
        }
    }
}
</style>
